import { accessDeniedToaster } from '../pop-denied-access'
import store from '@/store'
import { find } from 'lodash'

let guard = () => new Promise((resolve, reject) => {
  if (
    find(store.getters['auth/abilities'], ability =>
      ability.name === 'decommission-assets' &&
      ability.entity_type === 'Billow\\Models\\Asset'
    )
  ) {
    resolve()
  } else {
    accessDeniedToaster(reject, {
      name: 'dashboard'
    })
  }
})

export default {
  name: 'decommission-assets',
  run: guard
}