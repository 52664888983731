import { Route, RouteGroup } from '@/mixins/easy-route'
import { concat } from 'lodash'
export default concat(

  RouteGroup({
    middleware: ['auth', 'password-set', 'active-subscription'],

    parent: Route('/assets/:asset', '', () => import('@/views/asset/Manager')),

    routes: [
      Route('/', 'asset-overview', () => import('@/views/asset/Overview')),
      Route('tools', 'asset-tools', () => import('@/views/asset/manager/Tools')),
      Route('transfer', 'asset-transfer', () => import('@/views/asset/manager/Transfer'), ['transfer-assets-between-zones']),
      Route('components', 'asset-components', () => import('@/views/asset/manager/Components')),
      Route('documents', 'asset-documents', () => import('@/views/asset/manager/Documents'), ['manage-documentation']),
      Route('edit', 'asset-edit', () => import('@/views/asset/manager/Edit'), ['edit-assets']),
      Route('dynamic-statuses', 'asset-dynamic-statuses', () => import('@/views/asset/manager/DynamicStatuses'), ['edit-assets']),
      Route('inspections', 'asset-inspections', () => import('@/views/asset/manager/InspectionHistory')),
      Route('history', 'history', () => import('@/views/asset/manager/History')),
      Route('trashed-inspections', 'asset-trashed-inspections', () => import('@/views/asset/manager/TrashedInspections'), ['view-deleted-inspection-history']),
      Route('inspections/:inspection', 'inspection-evidence', () => import('@/views/asset/manager/InspectionEvidence')),
      Route('sensors', 'asset-sensors', () => import('@/views/asset/manager/Sensors')),
      Route('sensors/:sensor', 'asset-sensor-dashboard', () => import('@/views/asset/manager/SensorDashboard')),
    ]
  }),

  RouteGroup({
    middleware: ['auth', 'password-set', 'active-subscription'],
    routes: [
      Route('/assets', 'asset-admin-index', () => import('@/views/asset/AdminIndex')),
      Route('/asset-types', 'asset-type-index', () => import('@/views/asset-type/Index')),
      Route('/asset-type', 'create-asset-type', () => import('@/views/asset-type/Create'), ['manage-asset-types']),
      Route('/asset-dashboard', 'asset-admin-dashboard', () => import('@/views/dashboard/AdminAssets')),
    ]
  }),

  RouteGroup({

    middleware: ['auth', 'password-set', 'active-subscription', 'manage-asset-types'],

    parent: Route('/asset-type/:assetType', '', () => import('@/views/asset-type/Manager')),

    routes: [
      Route('/', 'asset-type-overview', () => import('@/views/asset-type/Overview')),
      Route('assets', 'asset-type-assets', () => import('@/views/asset-type/Assets')),
      Route('custom-fields', 'asset-type-custom-fields', () => import('@/views/asset-type/CustomFields')),
      Route('dynamic-statuses', 'asset-type-dynamic-statuses', () => import('@/views/asset-type/DynamicStatuses'),['manage-dynamic-statuses']),
      Route('edit', 'asset-type-edit', () => import('@/views/asset-type/Edit')),
    ]
  })
)
