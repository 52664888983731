import { Route, RouteGroup } from '@/mixins/easy-route'

export default RouteGroup({
  
  middleware: ['auth', 'password-set', 'active-subscription'],

  routes: [
    Route('/', 'dashboard', () => import('@/views/dashboard/Container')),
  ]
})
