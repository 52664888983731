import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import { concat } from 'lodash'
import { Route } from '@/mixins/easy-route'
import qs from 'qs'
import omitBy from 'lodash/omitBy'

import auth from './auth'
import common from './common'
import security from './user/security'
import adminSites from './site/admin'
import adminClients from './client/admin'
import adminCustomFieldTemplates from './custom-field-templates/admin'
import adminUsers from './user/admin'
import adminZones from './zone/admin'
import adminAssets from './asset/admin'
import adminBarcodes from './barcode/admin'
import adminChecklists from './checklist/admin'
import adminJobs from './job/admin'
import adminImports from './imports/admin'

import company from './company'
import supportTickets from './support/tickets'
import subscription from './subscription'
import download from './download'


export default new Router({

  mode: 'history',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',

  parseQuery: query => {
    return qs.parse(query)
  },

  stringifyQuery: query => {
    let queryString = qs.stringify(omitBy(query, value => {
      return value === '' || value === null
    }), {
      arrayFormat: 'brackets'
    })
    return (queryString) ? `?${queryString}` : ''
  },

  routes: concat(
    auth,
    common,
    security,
    adminSites,
    adminClients,
    adminUsers,
    adminZones,
    adminAssets,
    adminBarcodes,
    adminChecklists,
    adminCustomFieldTemplates,
    adminJobs,
    adminImports,
    company,
    supportTickets,
    subscription,
    download,
    Route('/not-found', 'not-found', () => import('@/views/common/NotFound')),
    Route('/maintenace', 'maintenance-mode', () => import('@/views/maintenance/MaintenanceMode')),
    Route('*', '404', () => import('@/views/common/NotFound')),
  )
})
