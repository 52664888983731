import { Route, RouteGroup } from '@/mixins/easy-route'
import { concat } from 'lodash'

export default concat(
  RouteGroup({

    middleware: [
      'auth', 'password-set', 'admin', 'active-subscription', 'site-import-assets'
    ],

    routes: [
      Route('/imports/:import/summary', 'import-summary', () => import('@/views/imports/ImportSummary')),
    ],
  }),
)
