import { Route, RouteGroup } from '@/mixins/easy-route'
import { concat } from 'lodash'

export default concat(
  RouteGroup({
    middleware: ['auth', 'admin', 'active-subscription'],

    routes:  [
      Route('/asset-groups', 'asset-group-index', () => import('@/views/asset-group/Index.vue')),
      Route('/sites/:site/zone', 'zone-create', () => import('@/views/zone/Create'))
    ]
  }),
  
  RouteGroup({
    middleware: ['auth', 'active-subscription', 'manage-zones'],

    parent: Route('/sites/:site/zones/:zone', '', () => import('@/views/zone/Manager')),

    routes:  [
      Route('/', 'zone-overview', () => import('@/views/zone/Assets')),
      Route('tools', 'zone-tools', () => import('@/views/zone/Tools')),
      Route('edit', 'zone-edit', () => import('@/views/zone/Edit')),
    ]
  })
)