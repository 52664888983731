import { Route, RouteGroup } from '@/mixins/easy-route'
import { concat } from 'lodash'

export default concat(
  
  Route('/verify-new-card', 'verify-card', () => import('@/views/company/VerifyCard')),

  RouteGroup({

    middleware: ['auth', 'password-set', 'admin'],
  
    parent: Route('/company', '', () => import('@/views/company/Manager')),
  
    routes: [
      Route('/', 'company-overview', () => import('@/views/company/Overview'), ['active-subscription']),
      Route('edit', 'company-edit', () => import('@/views/company/Edit'), ['active-subscription']),
        Route('naming-conventions', 'company-manage-naming-conventions', () => import('@/views/company/NamingConventions'), ['active-subscription']),
      Route('barcodes', 'company-barcodes', () => import('@/views/company/Barcodes'), ['active-subscription']),
      Route('users', 'company-users', () => import('@/views/company/Users'), ['active-subscription']),
    ]
  
  })
)
