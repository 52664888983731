import { Route, RouteGroup } from '@/mixins/easy-route'
import { concat } from 'lodash'

export default concat(
  RouteGroup({

    middleware: ['auth', 'password-set', 'active-subscription'],

    routes: [
      Route('/download/:filename', 'download-temp-file', () => import('@/views/download/Index')),
    ]
  }),
)
