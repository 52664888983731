import store from '@/store'
import { find } from 'lodash'
import { accessDeniedToaster } from '../pop-denied-access'

let guard = () => new Promise((resolve, reject) => {

  return find(store.getters['auth/abilities'], ability =>
    ability.name === 'site-import-assets' &&
    ability.entity_type === 'Billow\\Models\\Site',
  ) ? resolve() : accessDeniedToaster(reject, { name: 'dashboard' })
})

export default {
  name: 'site-import-assets',
  run: guard,
}