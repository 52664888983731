import store from '@/store'
import { find } from 'lodash'
import { accessDeniedToaster } from '../pop-denied-access'

let guard = () => new Promise((resolve, reject) => {
  return (
    find(store.getters['auth/abilities'], ability =>
      ability.name === 'close-jobs' &&
      ability.entity_type === 'Billow\\Models\\Job'
    )
  ) ? resolve() : accessDeniedToaster(reject, {
    name: 'dashboard'
  })
})

export default {
  name: 'close-jobs',
  run: guard
}