import { Route, RouteGroup } from '@/mixins/easy-route'

export default RouteGroup({

  middleware: ['auth', 'password-set', 'active-subscription'],

  routes: [
    Route('/barcodes', 'barcode-index', () => import('@/views/barcode/Index')),
  ]
  
})