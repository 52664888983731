import {Route, RouteGroup} from '@/mixins/easy-route'
import {concat} from 'lodash'

export default concat(
    RouteGroup({

        middleware: ['auth', 'admin', 'password-set', 'active-subscription'],

        prefix: '/admin',

        routes: [
            Route('/clients', 'client-admin-index', () => import('@/views/client/AdminIndex')),
            Route('/clients/deleted', 'deleted-clients-index', () => import('@/views/client/DeletedIndex'), ['view-deleted-clients']),
            Route('/client', 'client-admin-create', () => import('@/views/client/Create'), ['create-clients']),
        ]

    }),

    RouteGroup({

        middleware: ['auth', 'password-set', 'active-subscription'],

        parent: Route('/client/:client', '', () => import('@/views/client/manager/Index')),

        routes: [
            Route('/', 'client-overview', () => import('@/views/client/manager/Overview')),
            Route('sites', 'client-sites', () => import('@/views/client/manager/Sites')),
            Route('sites/deleted', 'client-deleted-sites', () => import('@/views/client/manager/DeletedSites')),
            Route('users', 'client-users', () => import('@/views/client/manager/Users')),
            Route('user', 'create-client-user', () => import('@/views/client/manager/CreateUser'), ['create-users']),
            Route('user/:user', 'edit-client-user', () => import('@/views/client/manager/EditUser'), ['edit-users']),
            Route('edit', 'client-edit', () => import('@/views/client/manager/Edit'), ['edit-clients']),
        ]

    }),

)
