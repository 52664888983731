import { Route, RouteGroup } from '@/mixins/easy-route'

export default RouteGroup({
  
  middleware: ['auth', 'password-set', 'active-subscription'],

  routes: [
    Route('/checklists', 'checklist-index', () => import('@/views/checklist/Index')),
    Route('/checklist', 'checklist-create', () => import('@/views/checklist/Create'), ['manage-checklists']),
    Route('/checklist/:checklist', 'checklist-edit', () => import('@/views/checklist/Edit'), ['manage-checklists']),
    Route('/checks', 'check-index', () => import('@/views/check/Index')),
  ]

})
