import store from '@/store'
import { find } from 'lodash'
import { accessDeniedToaster } from '../pop-denied-access'

let guard = () => new Promise((resolve, reject) => {
  if (
    find(store.getters['auth/abilities'], ability =>
      ability.name === 'manage-zones' &&
      ability.entity_type === 'Billow\\Models\\Site'
    )
  ) {
    return resolve()
  } else {
    accessDeniedToaster(reject, {
      name: 'dashboard'
    })
  }
})

export default {
  name: 'manage-zones',
  run: guard
}