import { Route, RouteGroup } from '@/mixins/easy-route'

export default RouteGroup({
  
  middleware: ['auth'],

  routes: [
    Route('/security/change-password', 'change-password', () => import('@/views/auth/ChangePassword'))
  ]
})
