import store from '@/store'
import { find } from 'lodash'
import { accessDeniedToaster } from "@/routes/guards/abilities/pop-denied-access";

let guard = () => new Promise( (resolve, reject) => {
    return (
        find(store.getters['auth/abilities'], ability =>
        ability.name === 'update-custom-fields' &&
        ability.entity_type === 'App\\Models\\CustomField'
        )
    ) ? resolve()
        : accessDeniedToaster(reject, {
            name: 'dashboard'
        })
})

export default {
    name: 'update-custom-fields',
    run: guard
}