import store from '@/store'

let guard = () => new Promise((resolve, reject) => {
  return (!store.getters['auth/authenticated']) ? resolve() : reject({
    name: 'dashboard'
  })
})

export default {
  name: 'guest',
  run: guard
}