import store from '@/store'

let guard = () => new Promise((resolve, reject) => {
  return (store.getters['company/isActive'] === true) ? resolve() : reject({
    name: 'account-disabled'
  })
})

export default {
  name: 'active-subscription',
  run: guard
}