import { Route, RouteGroup } from '@/mixins/easy-route'

export default RouteGroup({

  middleware: ['guest'],

  routes: [
    Route('/signin', 'signin', () => import('@/views/auth/SignIn')),
    Route('/signup', 'signup', () => import('@/views/auth/Signup')),
    Route('/two-factor', 'two-factor-challange', () => import('@/views/auth/TwoFactorChallenge')),
    Route('/security/forgot-password', 'forgot-password', () => import('@/views/auth/ForgotPassword')),
    Route('/security/password-reset/:token', 'reset-password', () => import('@/views/auth/ResetPassword'))
  ]
})
