import {Route, RouteGroup} from '@/mixins/easy-route'
import {concat} from 'lodash'

export default concat(
    RouteGroup({

        middleware: [
            'auth', 'password-set', 'active-subscription'
        ],

        routes: [
            Route('/profile', 'user-profile', () => import('@/views/user/Profile')),
        ]
    }),

    RouteGroup({

        middleware: [
            'auth', 'password-set', 'admin',
            'active-subscription', 'view-users'
        ],

        routes: [
            Route('/users', 'user-index', () => import('@/views/company/Users')),
            Route('/users/admins', 'user-admin-index', () => import('@/views/user/AdminIndex')),
            Route('/users/technicians', 'user-technician-index', () => import('@/views/user/TechnicianIndex')),
            Route('/users/import', 'user-import-index', () => import('@/views/user/ImportIndex'), ['import-users']),
        ]
    }),

    RouteGroup({

        middleware: ['auth', 'password-set', 'admin', 'view-users'],

        parent: Route('/user/:user', '', () => import('@/views/user/Manager')),

        routes: [
            Route('/', 'user-overview', () => import('@/views/user/Overview')),
            Route('edit', 'user-edit', () => import('@/views/user/Edit'), ['edit-users']),
            Route('job-history', 'user-job-history', () => import('@/views/user/JobHistory')),
            Route('documents', 'user-documents', () => import('@/views/user/Documents'), ['user-manage-documentation']),
            Route('site-access', 'user-site-access', () => import('@/views/user/SiteAccess'), ['manage-site-access']),
            Route('permissions', 'user-permissions', () => import('@/views/user/Permissions'), ['manage-permissions']),
        ]

    }),

    RouteGroup({
        middleware: ['auth', 'password-set', 'admin', 'view-users', 'manage-personas'],
        
        routes: [
            Route('/users/personas', 'user-persona-index', () => import('@/views/user/personas/Index.vue')),
            Route('/users/personas/create', 'user-persona-create', () => import('@/views/user/personas/Create.vue')),
            Route('/users/personas/transactions', 'user-persona-transactions', () => import('@/views/user/personas/Transactions.vue')),
            Route('/users/personas/:persona/edit', 'user-persona-edit', () => import('@/views/user/personas/Edit.vue')),
        ]
    })
)
