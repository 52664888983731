import { Route, RouteGroup } from '@/mixins/easy-route'

export default RouteGroup({

  middleware: ['auth', 'password-set'],
  prefix: '/support',

  routes: [
    Route('/tickets', 'support.tickets', () => import('@/views/support/Tickets')),
    Route('/company-tickets', 'support.company-tickets', () => import('@/views/support/Tickets')),
    Route('/tickets/:ticket', 'support.ticket', () => import('@/views/support/Ticket')),
  ]

})
