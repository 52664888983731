import {Route, RouteGroup} from "@/mixins/easy-route"
import {concat} from "lodash";

export default concat(
    RouteGroup({
        middleware: ['auth', 'password-set', 'active-subscription'],

        parent: Route('/custom-field-templates', '', () => import('@/views/custom-field-templates/Overview'), ['view-custom-field-templates']),

        routes: [
            Route('', 'custom-field-templates', () => import('@/views/custom-field-templates/Index'), ['view-custom-field-templates']),
            Route('create', 'create-custom-field-template', () => import('@/views/custom-field-templates/Create'), ['create-custom-field-templates']),
            Route('trashed', 'deleted-templates', () => import('@/views/custom-field-templates/DeletedTemplates'), ['view-trashed-custom-field-templates']),
            Route(':customFieldTemplate', 'custom-field-template', () => import('@/views/custom-field-templates/CustomFieldsIndex'), ['update-custom-field-templates']),
            Route(':customFieldTemplate/custom-field-create', 'template-create-custom-field', () => import('@/views/custom-field-templates/CustomFieldCreate'), ['create-custom-field-templates']),
            Route(':customFieldTemplate/assign-to-sites', 'template-assign-to-sites', () => import('@/views/custom-field-templates/AssignTemplateToSites'), ['assign-custom-field-template-to-sites']),
            Route(':customFieldTemplate/custom-field/:customField', 'template-custom-field-edit', () => import('@/views/custom-field-templates/CustomFieldEdit'), ['update-custom-field-templates']),
            Route(':customFieldTemplate/custom-field/:customField/predefined-list-create', 'template-create-predefined-list', () => import('@/views/custom-field-templates/PredefinedAnswersIndex'), ['create-custom-field-templates']),
        ]
    })
)