import store from '@/store'

let guard = () => new Promise((resolve, reject) => {
  return (store.getters['auth/authenticated']) ? resolve() : reject({
    name: 'signin'
  })
})

export default {
  name: 'auth',
  run: guard
}
