import { Route, RouteGroup } from '@/mixins/easy-route'
import { concat } from 'lodash'

export default concat(
  RouteGroup({

    middleware: ['auth', 'password-set', 'active-subscription'],

    routes: [
      Route('/job-types', 'job-type-index', () => import('@/views/job-type/Index')),
      Route('/job-types/create', 'job-type-create', () => import('@/views/job-type/Create')),
      Route('/job-types/:jobType', 'job-type-edit', () => import('@/views/job-type/Edit')),
      Route('/deleted-job-types', 'deleted-job-type-index', () => import('@/views/job-type/DeletedIndex')),
      Route('/jobs', 'job-index', () => import('@/views/job/Index')),
      Route('/recurring', 'recurring-job-index', () => import('@/views/recurring-job/Index')),
      Route('/calendar', 'job-calendar', () => import('@/views/job/Calendar')),
      Route('/calendar/schedule', 'job-schedule', () => import('@/views/job/JobSchedule')),
      Route('/job', 'job-create', () => import('@/views/job/Create'), ['create-jobs']),
      Route('/job/recurring', 'job-create-recurring', () => import('@/views/recurring-job/Create'), ['create-recurring-jobs']),
      Route('/inspections', 'inspection-index', () => import('@/views/inspection/Index')),
      Route('/upcoming-inspections', 'upcoming-inspection-index', () => import('@/views/inspection/UpcomingInspections')),
      Route('/overdue-inspections', 'overdue-inspection-index', () => import('@/views/inspection/OverdueInspections')),
      Route('/failed-checks', 'failed-checks-index', () => import('@/views/inspection/FailedChecks')),
      Route(`/job/:job/manager`, 'job-handler', () => import('@/views/job/Manager'))
    ]
  }),

  RouteGroup({

    middleware: ['auth', 'admin', 'password-set', 'manage-recurring-jobs'],
    parent: Route('/recurring/:recurringJob', '', () => import('@/views/recurring-job/Manager')),
    routes: [
      Route('/', 'recurring-job-manager', () => import('@/views/recurring-job/Overview')),
      Route('edit', 'recurring-job-edit', () => import('@/views/recurring-job/Edit')),
    ]

  }),
)
