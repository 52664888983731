import store from '@/store'
import { find } from 'lodash'
import { accessDeniedToaster } from "@/routes/guards/abilities/pop-denied-access";

let guard = () => new Promise( (resolve, reject) => {
    return (
        find(store.getters['auth/abilities'], ability =>
        ability.name === 'assign-custom-field-template-to-sites' &&
        ability.entity_type === 'App\\Models\\CustomFieldTemplate'
        )
    ) ? resolve()
        : accessDeniedToaster(reject, {
            name: 'dashboard'
        })
})

export default {
    name: 'assign-custom-field-template-to-sites',
    run: guard
}